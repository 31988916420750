@import url('https://fonts.googleapis.com/css?family=Anton');


.App {
  text-align: center;
  color: black;
}

/*Theme 1*/
:root{
  --theme: rgba(79, 52, 255, 0.85);
  --heading-theme: darkslategrey;
  --counter-color: white;
  --hover-color: lightblue;
  --text-color: white;
  --card-heading: rgba(39, 9, 235, 0.7);
  --card-color: rgba(39, 9, 235, 0.7);
  --card-text-color: white;
  --card-section-background: white;
  --counter-text-color: black;
}

.logo{
  margin-top: 2rem;
  /* width: 32rem;
  height: 20rem; */
  width: 40rem;
  height: 18rem;
}

.title{
  width: 100vw;
  text-shadow: 1px 3px lightgrey;
  color: var(--counter-text-color);
  margin-top: 3rem;
  display: flex;
  font-size: 2.8rem;
  font-weight:600;
  justify-content: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.title-text{
  width: 25rem;
  color: var(--counter-text-color);
  text-align: center;
  display: flex;
  font-size: 1.3rem;
  font-weight:500;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.navBar{
  z-index: 3;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  align-items: center;
  background-color: var(--heading-theme);
  position: sticky;
  top:0;
}

.navTab{
  margin-top: 40px;
  margin-bottom: 30px;
  color: var(--text-color);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
}

.navTab:hover{
  color: var(--hover-color);
  cursor: pointer;
  text-decoration-line: underline;
}

.image{
  height: 80px;
}

.body{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title-name{
  margin-top: 3.5vw;
  width: 50vw;
  text-align: center;
  font-family: Anton;
  color: rgba(60, 31, 253, 0.85);
  /* font-size: 9rem; */
  /* font-size: 9vw; */
  font-size: 4rem;
  /* padding: 0rem 5rem; */
  border: 0.9vw solid rgba(60, 31, 253, 0.85);
  border-radius: 3vw;
}

.about-section{
  /* margin-top: 3.5rem; */
  margin-top: 3.5vw;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--theme);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.ashokji_photo img{
  /* width: 22rem; */
  width: 20rem;
  height: 20rem;
  border-radius: 90vw;
  padding: 10px;
  border: 5px solid white;
}

.logo-div:hover{
  cursor: pointer;
}

.about-us-heading{
  font-size: 3rem;
  /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  /* font-family:Baskerville; */
  font-family: Anton;
  color: var(--text-color);
}

.about-us-body{
  line-height: 2.2rem;
  font-size: 1.3rem;
  color: var(--text-color);
  padding-left: 15rem;
  padding-right: 15rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
  text-align: center;
}

.practice-areas-section{
  border-top-width: 1.4px;
  border-top-style: groove;
  border-top-color: grey;
  background-color: var(--card-section-background);
  margin-top: 2rem;
  padding-top: 2rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.practice-areas-heading{
  font-size: 3rem;
  width: 100vw;
  text-align: center;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  /* font-weight: bold; */
  font-family: Anton;
  color: var(--card-heading);
}

.practice-areas-body{
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  flex-wrap: wrap;
  margin: auto;
}

.prac{
  margin: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--card-color);
  border-radius: 10px;
  width: 20rem;
  height: 20rem;
  color: var(--card-text-color);
  font-size: 1.7rem;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-family: Anton;
  /* border: 2px solid black; */
}

.prac:hover{
  cursor: pointer; 
  /* width: 20.5rem;
  height: 20.5rem; */
  margin: calc(2rem - 1px);
  text-decoration-line: underline;
  border: 1px solid darkblue;
  box-shadow: 2px 2px grey;
}

.prac-name{
  width: 10rem;
  text-align: center;
}

.accolades-section{
  border-top: 1.4px groove grey;
  margin-top: 5rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.accolades-heading{
  font-size: 3rem;
  width: 100vw;
  text-align: center;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: var(--theme);
  font-family: Anton;
  /* color: var(--text-color); */
}

.accolades-body{
  width: 60%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.button{
  height: 5rem;
  width: 5rem;
  background-color: var(--text-color);
  font-size: 2.5rem;
  color: var(--theme);
  border: 0px;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  font-family:monospace;
  font-weight: 500;
}

.button:hover{
  font-weight:600;
  cursor: pointer;
}

.accolades-img{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accolades-img img{
  width: 52rem;
  height: 28rem;
}

.blackdrop{
  position: absolute;
  top:0%;
  left:0%;
  width: 52rem;
  height: 28rem;
  /* width: 100%;
  height: 100%; */
  background-color: black;
  opacity: 0;
  animation: blackdrop-appear 3s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

@keyframes blackdrop-appear {
  0%{opacity: 0;}
  100%{opacity: 0.7;}
}

.accolades-info{
  text-align: center;
  color: white;
  z-index: 2;
  width: 50%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.4rem;
  position: absolute;
  top:50%;
  left:50%;
  opacity: 0;
  transform: translate(-50%,-50%);
  animation: accolades-info-appear 3s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

@keyframes accolades-info-appear {
  0%{opacity: 0;}
  100%{opacity: 1;} 
}

.contact-us-section{
  background-color: var(--heading-theme);
  margin-top: 3rem;
  padding-top: 1.7rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.contact-us-heading{
  font-size: 3rem;
  width: 100vw;
  text-align: center;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-family: Anton;
  color: var(--text-color);
}

.contact-us-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading1{
  margin-top: 1.2rem;
  color: var(--text-color);
  font-size: 1.8rem;
  text-decoration-line: underline;
}

.body1{
  font-size: 1rem;
  text-align: center;
  color: var(--text-color);
  width: 20rem;
}

.last{
  margin-bottom: 1rem;
}

.info-section{
  /* display: grid; */
  display: none;
  border-top: 1.4px groove grey;
  margin-top: 2rem;
  padding-top: 3rem;
  width: 58%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  /* background-color:blue; */
}

.info-heading{
  font-size: 3rem;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-family: Anton;
  color: var(--theme);
  justify-self: center;
  width: 60%;
}

.info-body{
  font-size: 1.4rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  /* background-color: blanchedalmond; */
}



@media(max-width:2000px) and (min-width: 1400px){
  .prac{
    margin: 2rem 2rem;
  }
}
@media(max-width:1400px) and (min-width: 1100px){
  .prac{
    margin: 2rem 2rem;
    width:18rem;
    height: 18rem;
  }
  .info-section{
    width: 65%;
  }
}

@media(max-width:1100px) and (min-width:900px){
  .info-section{
    width: 80%;
  }
  .accolades-img img{
    width: 42rem;
    height: 21rem;
  }
  .blackdrop{
    width: 42rem;
    height: 21rem;
  }
  .about-us-body{
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .accolades-info{
    font-size:1rem;
  }
}

@media (max-width: 900px) and (min-width: 800px){
  .info-section{
    width: 85%;
  }
  .accolades-img img{
    width: 35rem;
    height: 17rem;
  }
  .blackdrop{
    width: 35rem;
    height: 17rem;
  }
  .about-us-body{
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .info-heading{
    font-size:2.5rem;
  }
  .info-body{
    font-size: 1.2rem;
  }
  .accolades-info{
    font-size:0.8rem;
    width: 60%;
  }
}

@media (max-width: 800px) and (min-width: 700px){
  .info-section{
    width: 90%;
    display: none;
  }
  .accolades-img img{
    width: 28rem;
    height: 14rem;
  }
  .blackdrop{
    width: 28rem;
    height: 14rem;
  }
  .about-us-body{
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .info-heading{
    font-size:2.5rem;
  }
  .info-body{
    font-size: 1.2rem;
  }
  .accolades-info{
    font-size:0.8rem;
    width:60%;
  }
}
@media (max-width: 700px) and (min-width: 600px){
  .info-section{
    width: 90%;
    display: none;
  }
  .accolades-img img{
    width: 100%;
    height: 72vw;

  }
  .blackdrop{
    width:100%;
    height: 72vw;
  }
  .about-us-body{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .info-heading{
    font-size:2.5rem;
  }
  .info-body{
    font-size: 1.2rem;
  }
  .accolades-info{
    font-size:1rem;
    width:60%;
  }
  .accolades-body{
    position: relative;
    display:block;
    width:100%;
  }
  .leftbtn{
    position: absolute;
    left:0%;
    top:50%;
    background-color: rgba(255, 255, 255, 0);
    z-index:2;
    transform: translate(0%,-50%);
    color: white;
  }
  .rightbtn{
    position: absolute;
    right:0%;
    top:50%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0);
    transform: translate(0%,-50%);
    color: white;
  }
  .info-section{
    /* display: flex; */
    
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info-heading{
    width: 100%;
    font-size: 2.8rem;
    text-align: center;
  }
  .info-body{
    margin-top: 1rem;
  }
}
/* @media (max-width: 600px) and (min-width: 400px){
  .navBar{
    font-size: 0.7rem;
  }
  .navTab{
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
  .logo{
    margin-top: 2rem;
    width: 80vw;
    height: 50vw;
  }
  .info-section{
    width: 90%;
  }
  .accolades-img img{
    width: 100%;
    height: 72vw;

  }
  .blackdrop{
    width:100%;
    height: 72vw;
  }
  .about-us-body{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .info-heading{
    font-size:2.5rem;
  }
  .info-body{
    font-size: 1.2rem;
  }
  .accolades-info{
    font-size:1rem;
    width:60%;
  }
  .accolades-body{
    position: relative;
    display:block;
    width:100%;
  }
  .leftbtn{
    position: absolute;
    left:0%;
    top:50%;
    background-color: rgba(255, 255, 255, 0);
    z-index:2;
    transform: translate(0%,-50%);
    color: white;
  }
  .rightbtn{
    position: absolute;
    right:0%;
    top:50%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0);
    transform: translate(0%,-50%);
    color: white;
  }
  .info-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info-heading{
    width: 100%;
    font-size: 2.8rem;
    text-align: center;
  }
  .info-body{
    margin-top: 1rem;
  }
} */
@media (max-width: 600px) and (min-width: 0px){
  .navBar{
    /* font-size: 0.6rem; */
    font-size: 2.7vw;
  }
  .ashokji_photo img{
    /* width: 90vw;
    height: 90vw; */
    width: 70vw;
    height: 70vw;
    /* height: 81.81vw; */
    border-radius: 90vw;
  }
  .navTab{
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  .logo{
    margin-top: 2rem;
    /* width: 28rem;
    height: 17.5rem; */
    /* width: 80vw;
    height: 50vw; */
    width: 90vw;
    height: 40vw;
  }
  .info-section{
    width: 90%;
  }
  .about-us-heading{
    font-size: 10.5vw;
  }
  .about-us-body{
    line-height: 7.7vw;
    font-size: 4.55vw;
    color: var(--text-color);
    padding-bottom: 10.5vw;
    padding-top: 3.5vw;
    text-align: center;
  }
  .practice-areas-heading{
    font-size: 10.5vw;
  }
  .accolades-img img{
    /* width: 26rem;
    height: 14rem; */
    width: 100%;
    height: 72vw;

  }
  .blackdrop{
    /* width: 26rem; */
    /* height: 14rem; */
    width:100%;
    height: 72vw;
  }
  .about-us-body{
    /* padding-left: 2rem;
    padding-right: 2rem; */
    padding-left: 7vw;
    padding-right: 7vw;
  }
  .info-heading{
    font-size:2.5rem;
  }
  .info-body{
    /* font-size: 1.2rem; */
    font-size: 4.2vw;
  }
  .accolades-info{
    /* font-size:1rem; */
    font-size: 3.5vw;
    width:60%;
  }
  .accolades-body{
    position: relative;
    display:block;
    width:100%;
  }
  .leftbtn{
    position: absolute;
    left:0%;
    top:50%;
    background-color: rgba(255, 255, 255, 0);
    z-index:2;
    transform: translate(0%,-50%);
    color: white;
  }
  .rightbtn{
    position: absolute;
    right:0%;
    top:50%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0);
    transform: translate(0%,-50%);
    color: white;
  }
  .info-section{
    /* display: flex; */
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info-heading{
    width: 100%;
    /* font-size: 2.8rem; */
    font-size: 9.8vw;
    text-align: center;
  }
  .info-body{
    margin-top: 1rem;
    font-size: 4.9vw;
  }
  .prac{
    width: 35vw;
    height: 35vw;
    margin: 1rem 1rem;
    font-size: 3.5vw;
  }
  .prac-name{
    width: 70%;
  }
  .prac:hover{
    cursor: pointer; 
    /* width: 20.5rem;
    height: 20.5rem; */
    margin: calc(1rem - 1px);
    text-decoration-line: underline;
    border: 1px solid darkblue;
    box-shadow: 2px 2px grey;
  }
  
  .image{
    width: 20vw;
    height: 20vw;
  }
  .accolades-heading{
    font-size: 10.5vw;
  }
  .contact-us-heading{
    font-size: 10.5vw;
  }
  .heading1{
    margin-top: 1.2rem;
    color: var(--text-color);
    font-size: 6.3vw;
    text-decoration-line: underline;
  }
  
  .body1{
    font-size: 3.5vw;
    text-align: center;
    color: var(--text-color);
    width: 70vw;
  }
}